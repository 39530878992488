import React, { Component } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useSelector, useDispatch } from "react-redux";
import { logIn } from "../actions";
import IconButton from "@material-ui/core/IconButton";

export default function MenuProfile() {
  const { loginWithRedirect, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleLogin = (event) => {
    loginWithRedirect();
  };

  const handleLogout = (event) => {
    logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const userRedux = useSelector((state) => state.user);

  return (
    <div>
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {userRedux.loggedIn == true ? (
            <p className="welcomePadding"> Welcome {userRedux.name} </p>
          ) : (
            ""
          )}
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {userRedux.loggedIn == true ? (
            <MenuItem onClick={handleClose}>Profile</MenuItem>
          ) : (
            <MenuItem onClick={handleLogin}>Login</MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
}
