import Container from "@material-ui/core/Container";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

export default function NotSignedIn() {
  const { loginWithRedirect, user, logout, isAuthenticated } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  const styles = {
    largeIcon: {
      width: 100,
      height: 100,
    },
  };
  return (
    <Container maxWidth="sm" minHeight="sm">
      <div>
        <h1>You're not signed in</h1>
        <div>
          <SentimentVeryDissatisfiedIcon
            style={styles.largeIcon}
          ></SentimentVeryDissatisfiedIcon>
        </div>
        <div>
          <button className="buttonSignIn" onClick={handleLogin}>
            Sign In/Register
          </button>
        </div>
      </div>
    </Container>
  );
}
