import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { logIn } from "../actions";
import NotSignedIn from "./NotSignedIn";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";

export default function WhatsappForm() {
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [number, setNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [valueNumber, setValueNumber] = useState();
  const divStyle = {
    paddingTop: "27px",
    paddingRight: "12px",
    paddingBottom: "10px",
    paddingLeft: "12px",
    width: "100%",
    height: "1.1876em",
  };

  const { loginWithRedirect, user, logout, isAuthenticated } = useAuth0();

  const handleChangeNumber = (event) => {
    setNumber(event.target.value);
  };

  const handleChangeMessgae = (event) => {
    setMessage(event.target.value);
  };

  const handleResetState = () => {
    setLoading(false);
    setSubmitClicked(false);
  };

  const handleLogin = (event) => {
    loginWithRedirect();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitClicked(true);

    let dataObj = {
      phone: valueNumber,
      message: message,
    };
    console.log(valueNumber);

    let data = JSON.stringify(dataObj);
    console.log(data);

    axios
      .post(
        "https://urlshortener-api-dev.a2d24.dev/create-whatsapp/",
        (data = data)
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          setLoading(false);
          setPostSuccess(true);
          setUrl(response.data.short_url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userRedux = useSelector((state) => state.user);
  if (userRedux.loggedIn == true) {
    return isLoading ? (
      <Container maxWidth="sm" minHeight="sm" className="formWrapper">
        <div className="formItemPadding">
          <CircularProgress />
        </div>
      </Container>
    ) : submitClicked ? (
      postSuccess ? (
        <Container maxWidth="sm" minHeight="sm" className="formWrapper">
          <div className="formItemPadding">
            <h1>Success</h1>
            <a href={url}>{url}</a>
          </div>
          <div className="formItemPadding">
            <button onClick={handleResetState}>Back</button>
          </div>
        </Container>
      ) : (
        <Container maxWidth="sm" minHeight="sm" className="formWrapper">
          <div className="formItemPadding">
            <h1>Failed</h1>
          </div>
          <div className="formItemPadding">
            <button onClick={handleResetState}>Back</button>
          </div>
        </Container>
      )
    ) : (
      <Container maxWidth="sm" className="formWrapper">
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <h1>Submit Whatsapp Details</h1>
          <Container maxWidth="xs">
            <PhoneInput
              className="phoneInputStyle"
              placeholder="Enter phone number"
              value={valueNumber}
              onChange={setValueNumber}
            ></PhoneInput>
          </Container>

          <div className="formItemPadding">
            <TextField
              id="filled-margin-none"
              label="Message"
              name="message"
              variant="filled"
              placeholder="Hey, A2D24 Team"
              value={message}
              onChange={handleChangeMessgae}
            />
          </div>

          <button type="submit">Submit</button>
        </form>
      </Container>
    );
  } else {
    return <NotSignedIn></NotSignedIn>;
  }
}
