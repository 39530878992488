import React, { useState } from "react";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { logIn } from "./actions";
import UrlForm from "./components/urlForm";
import MyUrls from "./components/myUrls";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import WhatsappForm from "./components/whatsappForm";
import LoginButton from "./components/LoginButton";
import MenuProfile from "./components/MenuProfile";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuLogo: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

export function NavBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className="computer Margins">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuLogo}
            color="inherit"
            aria-label="menu"
          >
            <h4>Url Shortener Logo</h4>
          </IconButton>
          <Typography variant="h6" className={classes.title}></Typography>
          <MenuProfile></MenuProfile>
          <Button color="inherit">Home</Button>
          <Button color="inherit">About</Button>
          <Button color="inherit">Contact</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://a2d24.com/">
        A2D24
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  const {
    loginWithRedirect,
    user,
    logout,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  const [firstRender, setFirstRender] = useState(() => {
    return true;
  });
  //const counter = useSelector((state) => state.counter);
  const imgMyimageexample = require("./assets/bottom.png");
  const divStyle = {
    width: "100%",
    height: "250px",
    backgroundImage: `url(${imgMyimageexample})`,
    backgroundSize: "cover",
  };
  const dispatch = useDispatch();
  const classes = useStyles();

  const userT = {
    name: "hunter",
    surname: "Motsumi",
    email: "hunt@mail.com",
  };
  if (firstRender == true) {
    if (isLoading === false) {
      if (isAuthenticated) {
        setFirstRender(false);
        console.log(user);
        dispatch(
          logIn({
            name: user.given_name,
            surname: user.family_name,
            email: user.email,
          })
        );
      }
    }
    console.log(isAuthenticated);
  }
  return (
    <div className="App">
      <NavBar></NavBar>
      {isLoading === true ? (
        <Container maxWidth="md" className="containerPadding">
          <div className="formItemPadding">
            <CircularProgress />
          </div>
        </Container>
      ) : (
        <Container maxWidth="md" className="containerPadding">
          <Tabs>
            <TabList>
              <Tab>Create Url</Tab>
              <Tab>Whatsapp Url</Tab>
              <Tab>My Url</Tab>
            </TabList>

            <TabPanel>
              <UrlForm />
            </TabPanel>
            <TabPanel>
              <WhatsappForm />
            </TabPanel>
            <TabPanel>
              <MyUrls />
            </TabPanel>
          </Tabs>
        </Container>
      )}
      <div style={divStyle}></div>
      <footer>
        <Container maxWidth="sm">
          <Typography variant="body1">
            Url Shortener and Whatsapp Link Generator
          </Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
    /*<div className="App">
     <button onClick={() => dispatch(logIn(userT))}>-</button>
      <UrlForm />
      <MyUrls />
      <h1> Redux Test Counter {counter}</h1>
      <button onClick={() => dispatch(increment())}>+</button>
    </div>*/
  );
}

export default App;
