import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { email } from "../Validators";
import { useSelector, useDispatch } from "react-redux";
import { logIn } from "../actions";
import { useAuth0 } from "@auth0/auth0-react";
import NotSignedIn from "./NotSignedIn";

export default function UrlForm() {
  const [targetUrl, setTargetUrl] = useState("");
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const { loginWithRedirect, user, logout } = useAuth0();
  const userRedux = useSelector((state) => state.user);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeUrl = (event) => {
    setTargetUrl(event.target.value);
  };

  const handleResetState = () => {
    setLoading(false);
    setSubmitClicked(false);
  };

  const handleLogin = (event) => {
    loginWithRedirect();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitClicked(true);

    let dataObj = {
      email: userRedux.email,
      targetUrl: targetUrl,
    };
    console.log(dataObj);

    let data = JSON.stringify(dataObj);
    console.log(data);

    axios
      .post(
        "https://urlshortener-api-dev.a2d24.dev/create-short/",
        (data = data)
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          setLoading(false);
          setPostSuccess(true);
          setUrl(response.data.short_url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (userRedux.loggedIn == true) {
    return isLoading ? (
      <Container maxWidth="sm" minHeight="sm" className="formWrapper">
        <div className="formItemPadding">
          <CircularProgress />
        </div>
      </Container>
    ) : submitClicked ? (
      postSuccess ? (
        <Container maxWidth="sm" minHeight="sm" className="formWrapper">
          <div className="formItemPadding">
            <h1>Success</h1>
            <a href={url}>{url}</a>
          </div>
          <div className="formItemPadding">
            <button className="buttonClick" onClick={handleResetState}>
              Back
            </button>
          </div>
        </Container>
      ) : (
        <Container maxWidth="sm" minHeight="sm" className="formWrapper">
          <div className="formItemPadding">
            <h1>Failed</h1>
          </div>
          <div className="formItemPadding">
            <button className="buttonClick" onClick={handleResetState}>
              Back
            </button>
          </div>
        </Container>
      )
    ) : (
      <Container maxWidth="sm">
        <form
          className="formWrapper"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <h1>Submit Url</h1>

          {/*<div className="formItemPadding">
            <TextField
              id="filled-margin-none"
              label="Email"
              name="email"
              variant="filled"
              placeholder="myemail@a2d24.com"
              value={email}
              onChange={handleChangeEmail}
            />
    </div>*/}

          <div className="formItemPadding">
            <TextField
              id="filled-margin-none"
              label="Url"
              name="targetUrl"
              variant="filled"
              placeholder="https://www.2d24.com"
              value={targetUrl}
              onChange={handleChangeUrl}
            />
          </div>
          <button className="buttonClick" type="submit">
            Submit
          </button>
        </form>
      </Container>
    );
  } else {
    return <NotSignedIn></NotSignedIn>;
  }
}
