import counterReducter from "./counter";
import { combineReducers } from "redux";
import isLoadingReducer from "./isLoading";
import loggedInReducer from "./user";
import userReducer from "./user";

const allReducers = combineReducers({
  counter: counterReducter,
  user: userReducer,
  isLoading: isLoadingReducer,
});

export default allReducers;
