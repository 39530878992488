import React, { Component, useState } from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import NotSignedIn from "./NotSignedIn";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function MyUrls() {
  const [urls, setUrls] = useState([]);
  const [contact, setContact] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [urlDataRecieved, setUrlDataReceievd] = useState(false);
  const { loginWithRedirect, user, logout } = useAuth0();
  const userRedux = useSelector((state) => state.user);
  const [valueNumber, setValueNumber] = useState();

  const handleNumberChange = (event) => {
    setContact(event.target.value);
  };

  const handleLogin = (event) => {
    loginWithRedirect();
  };

  const handleSubmitUrl = () => {
    setLoading(true);
    let data = {
      contact: userRedux.email,
    };
    axios
      .post("https://urlshortener-api-dev.a2d24.dev/get-urls", (data = data))
      .then((response) => {
        console.log(response);
        setLoading(false);
        setUrlDataReceievd(true);
        setUrls(response.data.url_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitWhatsapp = () => {
    setLoading(true);
    let data = {
      contact: valueNumber,
    };
    axios
      .post("https://urlshortener-api-dev.a2d24.dev/get-urls", (data = data))
      .then((response) => {
        console.log(response);
        setLoading(false);
        setUrlDataReceievd(true);
        setUrls(response.data.url_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResetState = () => {
    setLoading(false);
    setUrlDataReceievd(false);
  };

  return (
    <div>
      <h2>Url Type</h2>
      <Tabs>
        <TabList>
          <Tab>Urls</Tab>
          <Tab>Whatsapp</Tab>
        </TabList>
        {userRedux.loggedIn == false ? (
          <NotSignedIn></NotSignedIn>
        ) : isLoading ? (
          <Container maxWidth="sm" minHeight="sm" className="formWrapper">
            <div className="formItemPadding">
              <CircularProgress />
            </div>
          </Container>
        ) : urlDataRecieved ? (
          <Container maxWidth="sm" minHeight="sm" className="formWrapper">
            <div className="formItemPadding">
              <h1>Your Urls</h1>
            </div>
            <div>
              {urls.length > 0 ? (
                urls.map((url) => (
                  <div className="urlTextsize" key={url.id}>
                    <a
                      href={"https://urlshortener-api-dev.a2d24.dev/" + url.id}
                    >
                      https://urlshortener-api-dev.a2d24.dev/{url.id}
                    </a>
                  </div>
                ))
              ) : (
                <div>
                  <h3>No urls for your email</h3>
                </div>
              )}
            </div>
            <div className="formItemPadding">
              <button className="buttonClick" onClick={handleResetState}>
                Back
              </button>
            </div>
          </Container>
        ) : (
          <div>
            <TabPanel>
              <Container maxWidth="sm" minHeight="sm">
                <form
                  className="formWrapper"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmitUrl}
                >
                  <div>
                    <h1>Get my Urls</h1>
                    <div className="formItemPadding">
                      <p>User email: {userRedux.email}</p>
                    </div>
                    <button className="buttonClick" type="submit">
                      Retrieve
                    </button>
                  </div>
                </form>
              </Container>
            </TabPanel>
            <TabPanel>
              <Container maxWidth="sm" minHeight="sm">
                <form
                  className="formWrapper"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmitWhatsapp}
                >
                  <div>
                    <h1>Get my Whatsapp links</h1>
                    <Container maxWidth="xs">
                      <PhoneInput
                        className="phoneInputStyle"
                        placeholder="Enter phone number"
                        value={valueNumber}
                        onChange={setValueNumber}
                      ></PhoneInput>
                    </Container>

                    <button className="buttonClick" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </Container>
            </TabPanel>
          </div>
        )}
      </Tabs>
    </div>
  );
  {
    /*if (userRedux.loggedIn == true) {
    return isLoading ? (
      <Container maxWidth="sm" minHeight="sm" className="formWrapper">
        <div className="formItemPadding">
          <CircularProgress />
        </div>
      </Container>
    ) : urlDataRecieved ? (
      <Container maxWidth="sm" minHeight="sm" className="formWrapper">
        <div className="formItemPadding">
          <h1>Your Urls</h1>
        </div>
        <div>
          {urls.length > 0 ? (
            urls.map((url) => (
              <div className="urlTextsize" key={url.id}>
                <a href={"https://urlshortener-api-dev.a2d24.dev/" + url.id}>
                  https://urlshortener-api-dev.a2d24.dev/{url.id}
                </a>
              </div>
            ))
          ) : (
            <div>
              <h3>No urls for your email</h3>
            </div>
          )}
        </div>
        <div className="formItemPadding">
          <button className="buttonClick" onClick={handleResetState}>
            Back
          </button>
        </div>
      </Container>
    ) : (
      <Container maxWidth="sm" minHeight="sm">
        <form
          className="formWrapper"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div>
            <h1>Get my Urls</h1>
            <div className="formItemPadding">
              <TextField
                id="filled-margin-none"
                label="Email"
                variant="filled"
                placeholder="myemail@a2d24.com"
                value={contact}
                onChange={handleEmailChange}
              />
            </div>
            <button className="buttonClick" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Container>
    );
  } else {
    return <NotSignedIn></NotSignedIn>;
  }*/
  }
}
