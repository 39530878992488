const userReducer = (state = { loggedIn: false }, action) => {
  switch (action.type) {
    case "LOGIN":
      state = {
        loggedIn: true,
        name: action.payload.name,
        surname: action.payload.surname,
        email: action.payload.email,
      };
      return state;
    case "LOGOUT":
      state = {
        loggedIn: false,
      };
      return state;
    default:
      return state;
  }
};

export default userReducer;
