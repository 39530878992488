export const increment = () => {
  return {
    type: "INCREMENT",
  };
};
export const decrement = () => {
  return {
    type: "DECREMENT",
  };
};

export const multiply = (number) => {
  return {
    type: "MULTIPLY",
    payload: number,
  };
};

export const logIn = (value) => {
  return {
    type: "LOGIN",
    payload: {
      name: value.name,
      surname: value.surname,
      email: value.email,
      loggedIn: true,
    },
  };
};

export const logOut = () => {
  return {
    type: "LOGOUT",
  };
};

export const loaderOn = (value) => {
  return {
    type: "LOADERON",
  };
};

export const loaderOFF = (value) => {
  return {
    type: "LOADEROFF",
  };
};
